import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import { LayoutHeader } from "./ui/layout-components"
import { elevation2 } from "../utils/elevation"

const headerHeight = 52

const HeaderSpacer = styled.div`
  height: ${headerHeight}px;
`

const HeaderBlock = styled(LayoutHeader)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight}px;
  background-color: #fff;
  box-shadow: ${elevation2};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
`

const SiteLogo = styled.h1`
  color: #999;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  vertical-align: center;
  margin: 0;
  padding: 0;
`

const Header = ({ siteTitle }) => (
  <>
    <HeaderBlock>
      <SiteLogo>{siteTitle}</SiteLogo>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1.45rem 1.0875rem`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
    </HeaderBlock>
    <HeaderSpacer />
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
