import styled from "@emotion/styled"
import { superWide } from "../../utils/breakpoints"
import { elevation1 } from "../../utils/elevation"

export const LayoutHeader = styled.header`
  grid-area: header;
  position: relative;
  z-index: 2;
`
export const LayoutMain = styled.main`
  grid-area: main;
`
export const LayoutFooter = styled.footer`
  grid-area: footer;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${superWide}px) {
    box-shadow: ${elevation1};
    align-items: stretch;
  }
`

export const LayoutRoot = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-areas:
    "header"
    "main"
    "footer";

  @media (min-width: ${superWide}px) {
    grid-template-columns: auto 500px;
    grid-template-areas:
      "header header"
      "main footer";
  }
`
